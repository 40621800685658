import {BigNumber, ethers} from "ethers";
import {ADDRESS, CONTRACT_ADDRESS, NODE_PROVIDER_URL, TOKEN_ID, UOB_SIGNER} from "../constants";
import PBMabi from '../contract/bin/PBM.abi.json';
import CryptoJS from 'crypto-js';

const Elliptic = require('elliptic').eddsa;

const provider = new ethers.providers.JsonRpcProvider(NODE_PROVIDER_URL);
const signer = new ethers.Wallet(UOB_SIGNER, provider);


const ec = new Elliptic('ed25519');
const PBMabiString = JSON.stringify(PBMabi);
const readPBMContract = new ethers.Contract(CONTRACT_ADDRESS, PBMabiString, provider);
const writePBMContract = new ethers.Contract(CONTRACT_ADDRESS, PBMabiString, signer);

function createSHA256Hash(seed) {
    return CryptoJS.SHA256(seed).toString(CryptoJS.enc.Hex);
}

export function generateAddressAndKeyFromSeed(seed) {
    let visitor = {};
    let secret = createSHA256Hash(seed);
    let keyPair = ec.keyFromSecret(secret);
    let privateKey = Array.from(keyPair.getSecret(), function (byte) {
        return ('0' + (byte & 0xFF).toString(16)).slice(-2);
    }).join('');
    visitor.signer = new ethers.Wallet(privateKey).connect(provider);
    visitor.address = new ethers.Wallet(privateKey).address;
    return visitor;
}

function getAddressFromSeedIfRequired(address) {
    if (address === undefined || address === null || address === '') {
        throw new Error("Secret Code is required");
    }

    if (address.startsWith("0x")) {
        return address;
    } else {
        return generateAddressAndKeyFromSeed(address).address;
    }
}

/**
 * This function is used to get the balance of PBM tokens for a given address.
 * If the address is not in the correct format (does not start with "0x"), it is treated as a seed and converted into an address.
 *
 * @async
 * @export
 * @param {string} address - The address or seed to get the balance for.
 * @throws Will throw an error if the address is not defined, null or an empty string.
 * @returns {Promise<string>} A promise that resolves to the balance of PBM tokens as a string.
 */
export async function getPbmBalance(address) {
    address = getAddressFromSeedIfRequired(address);

    console.log("PBM Balance Inquiry with tokenId:" + parseInt(TOKEN_ID) + ", and address:" + address);
    const pbmBalance = await readPBMContract.balanceOf(address, parseInt(TOKEN_ID));
    return pbmBalance.toString();
}

export async function getNextNonce() {
    return await provider.getTransactionCount(ADDRESS);
}

export async function mintPBM(toAddress, tokenAmount, nonce) {
    toAddress = getAddressFromSeedIfRequired(toAddress);
    const gasMultiplier = 200;
    console.log("mintPBM with tokenId:" + parseInt(TOKEN_ID) + ", amount:" + tokenAmount + ", receiver to address:" + toAddress + ", and nonce:" + nonce);
    let estimation = await writePBMContract.estimateGas.mint(parseInt(TOKEN_ID), BigNumber.from(tokenAmount), toAddress);
    console.log("Base gas estimate:" + BigNumber.from(estimation));

    let value = await writePBMContract.mint(parseInt(TOKEN_ID), BigNumber.from(tokenAmount), toAddress, {gasLimit: estimation.mul(gasMultiplier).div(100), nonce: nonce});
    return JSON.parse(JSON.stringify(value))
}

export async function addGas(toAddress, nonce) {
    console.log("addGas with to address:" + toAddress)
    // return {hash: "0x1234567890"}

    toAddress = getAddressFromSeedIfRequired(toAddress);
    const txnReqData = {"to": toAddress, "value": ethers.utils.parseEther("0.001"), "nonce": nonce};
    const gasResult = await signer.sendTransaction(txnReqData)
    console.log("Gas Transaction Results: " + gasResult.hash);
    return gasResult;
}


