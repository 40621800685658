import React, {useState} from 'react';
import {Spinner, Button, Form, Container, Row, Col} from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';


import {getPbmBalance} from "../controllers/crypto-operations";
import {POLYGON_URL_PREFIX} from "../constants";

import QRCode from 'qrcode.react';
import {getTransaction, mintPBM} from "../controllers/backend";

function sendEvent(event: string) {
    // @ts-ignore
    window.gtag('event', event, {
        'event_category': 'User Interactions',
        'event_label': event
    });
}

const MintPBMForm: React.FC = () => {
    const [toAddress, setToAddress] = useState<string>('');
    const [txnHash, setTxnHash] = useState<string>('');
    const [balance, setBalance] = useState<string>('-');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [status, setStatus] = useState<string>('');


    const handleMint = async () => {
        setIsLoading(true);
        sendEvent('min_pbm');
        setStatus('Minting GBT Coin')
        var nonce = null;
        try {

            const transaction_id = await mintPBM(toAddress);

            const checkTransactionStatus = async (transaction_id:string) => {
                var transaction = await getTransaction(transaction_id);
                setStatus(transaction.status)
                setTxnHash(transaction.hash)
                console.log("Hash" + transaction.hash);

                if (transaction.status !== "DONE" && transaction.status !== "FAILED") {
                    setTimeout(() => checkTransactionStatus(transaction_id), 2000);
                } else {
                    console.log('Minting complete ');
                    setIsLoading(false);
                    setTimeout(() => {
                        setStatus('')
                    }, 2000);
                }
            };

            await checkTransactionStatus(transaction_id);

        } catch (error) {
            console.error('Error during minting: ' + nonce, error);
            setStatus('')
            toast.error((error as Error).message);
            setIsLoading(false);
        } finally {
        }
    };

    const handleBalance = async () => {
        setIsLoading(true);
        sendEvent('get_balance');
        setStatus('Fetching Balance')
        try {
            const balanceValue = await getPbmBalance(toAddress);
            console.log(balanceValue);
            setBalance(balanceValue);
            setStatus('Balance Fetched')
            setTimeout(() => {
                setStatus('')
            }, 2000);
        } catch (error) {
            console.error('Error during balance fetching:', error);
            setStatus('')
            toast.error((error as Error).message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container>
            <ToastContainer />
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                    <br/>
                    <br/>
                    {/*<img alt={"Logo"} width={"20%"} src={"gbt_coin_logo.png"}/>*/}
                    <br/>
                    <br/>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col md={6}>
                    <h2>Reimagine Money with GBT Coins</h2>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col md={6}>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Your Identifier</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your Secret Code"
                                value={toAddress}
                                name="to_address"
                                onChange={(e) => setToAddress(e.target.value)}
                                disabled={isLoading}
                            />
                        </Form.Group>
                        <div data-test-id={'balance_text'}>Balance: {balance}</div>
                        <Button
                            variant="light"
                            onClick={handleMint}
                            disabled={isLoading}
                            name="mint_button"
                        >
                            {isLoading ? <Spinner as="span" animation="border" size="sm" role="status"
                                                  aria-hidden="true"/> : 'Mint GBT Coin'}
                        </Button> &nbsp;
                        <Button
                            variant="light"
                            onClick={handleBalance}
                            disabled={isLoading}
                            name="balance_button"
                        >
                            {isLoading ? <Spinner as="span" animation="border" size="sm" role="status"
                                                  aria-hidden="true"/> : 'Get Balance'}
                        </Button> {status}

                    </Form>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col md={6}  className="text-center">
                    <br/>
                    {txnHash && <a href={`${POLYGON_URL_PREFIX}${txnHash}`} data-test-id={'hash_url'} target="_blank" rel="noreferrer">{txnHash}</a>}
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col md={6}  className="text-center">
                    <br/>
                    {toAddress && <QRCode value={toAddress} />}
                </Col>
            </Row>
        </Container>
    );
};

export default MintPBMForm;
