// export const WEBSITE_URL = "http://localhost:3000";
export const WEBSITE_URL = "'https://gbt-coin-demo.pages.dev/'";
export const NONCE_API_URL   = 'http://localhost:8000/api/nonce/';
export const MINT_API_URL = 'https://api.iphonesdksnippets.com/api/mint/';
export const TRANSACTION_API_URL = 'https://api.iphonesdksnippets.com/api/transaction/';

export const NODE_PROVIDER_URL = "https://nd-511-975-583.p2pify.com/88833eaf9e6cfe1c7c942bbff4738a5e";
export const UOB_SIGNER = "f3dfb800637e17dac00e9f04d810be92e56039b0be2b612974053159079ec9ec";

export const CONTRACT_ADDRESS = "0x512c07BAd2C9EccE767Ac0cE222761f49C15e15a"
export const ADDRESS = "0xEBBf19132436e58EccFfdFe05ac043A508FA2F83"

export const POLYGON_URL_PREFIX = "https://mumbai.polygonscan.com/tx/"
export const TOKEN_ID = "0"
export const TOKEN_AMOUNT = "5"
