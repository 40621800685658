import {NONCE_API_URL, MINT_API_URL, TRANSACTION_API_URL} from "../constants";

export async function getNonceFromService(potential_nonce, address) {
  const response = await fetch(NONCE_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      potential_nonce: potential_nonce,
      address: address
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const res = await response.json();
  console.log(res);
  return res.nonce;
}

export async function mintPBM(address) {
  const response = await fetch(MINT_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      address: address,
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const res = await response.json();
  console.log(res);
  return res.transaction_id;
}

export async function getTransaction(transactionId) {
  const response = await fetch(TRANSACTION_API_URL + transactionId + "/", {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const res = await response.json();
  console.log(res);
  return res;
}
