import React from 'react';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MintPBMForm from "./screens/MintScreen";


function App() {
  return (
      <MintPBMForm/>
  );
}

export default App;
